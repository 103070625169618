import { TranslocoService } from '@jsverse/transloco';
import { TranslocoModule } from '@jsverse/transloco';
import { ProductsService } from '../../../core/products-service/products.service';
import { MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { UiService } from '../../../core/ui-service/ui.service';
import { Component, inject, OnInit } from '@angular/core';
import { Admin } from 'src/app/core/interfaces/admin';
import { finalize } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import { NgxMaskModule } from 'ngx-mask';
import { ConfigService } from '../../../core/config/config.service';
import { ANALYTICS_PROVIDER } from '@futura/futura-ui';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
  standalone: true,
  imports: [TranslocoModule, MatIcon, MatDialogClose, MatDialogTitle, MatDialogContent, NgxMaskModule],
})
export class SupportDialogComponent implements OnInit {
  private readonly analyticsService = inject(ANALYTICS_PROVIDER);
  affiliated_admins: { tutors: Array<Admin>; customer_cares: Array<Admin> } = { tutors: [], customer_cares: [] };

  customer_care_phone_number: string = '';
  faq_link = '';

  constructor(
    private uiService: UiService,
    private productsService: ProductsService,
    private translate: TranslocoService,
    private dialogRef: MatDialogRef<SupportDialogComponent>,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.uiService.showSpinner = true;
    this.configService.getUiConfig('_faq_link').subscribe(fl => (this.faq_link = fl));
    this.configService.getUiConfig('_customer_care_phone_number').subscribe(ccpn => {
      this.customer_care_phone_number = ccpn;
      this.affiliated_admins.customer_cares.push({
        id: '',
        name: 'Customer',
        surname: 'Care',
        image: '',
        phone: ccpn,
        prefix: '',
      });
    });
    this.productsService
      .getAffiliatedAdmins()
      .pipe(finalize(() => (this.uiService.showSpinner = false)))
      .subscribe({
        next: admins => {
          this.affiliated_admins.tutors = admins.tutors;
          if (admins.customer_cares.length > 0) this.affiliated_admins.customer_cares = admins.customer_cares;
        },
        error: () => {
          this.uiService.errorToast({ title: this.translate.translate('utils.errors.general') });
          this.dialogRef.close();
        },
      });
  }

  goFaq() {
    this.analyticsService?.sendAnalytics({ eventName: 'faq_page_open', eventParams: {} });
    window.open(this.faq_link, '_blank');
  }

  goToWhatsapp(phone_number: string) {
    window.open(`https://wa.me/${phone_number}`, '_blank');
  }
}
