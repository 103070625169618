import { DynamicDialogService } from '../../../core/dynamic-dialogs-service/dynamic-dialog.service';
import { UtilsService } from 'src/app/core/utils-service/utils.service';
import { User } from 'src/app/core/interfaces/user';
import { MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { TranslocoModule } from '@jsverse/transloco';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { UserService } from 'src/app/core/user-service/user.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AnalyticsButtonDirective } from '@futura/futura-ui/analytics';

@Component({
  selector: 'app-free-trial-invite-dialog',
  templateUrl: './free-trial-invite-dialog.component.html',
  styleUrls: ['./free-trial-invite-dialog.component.scss'],
  standalone: true,
  imports: [NgClass, MatIcon, MatDialogClose, ProgressbarModule, MatDialogContent, TranslocoModule, AnalyticsButtonDirective],
})
export class FreeTrialInviteDialogComponent implements OnInit, OnDestroy {
  link?: string;

  hour: string = '00';
  minute: string = '00';
  second: string = '00';
  percent: number = 0.1;

  user?: User;

  ui_update_interval?: ReturnType<typeof setTimeout>;

  message_choosed = '';

  Math = Math;

  toptitle: string = '';
  title: string = '';
  content: string = '';
  countdown_license = '';
  friends_to_invite = 0;
  show_extra_groups = false;

  telegram_groups = [];
  whatsapp_groups = [];

  constructor(
    private userService: UserService,
    private uiService: UiService,
    private utils: UtilsService,
    private translate: TranslocoService,
    private dialogRef: MatDialogRef<FreeTrialInviteDialogComponent>,
    private dynamicDialog: DynamicDialogService
  ) {}

  ngOnInit(): void {
    this.uiService.showSpinner = true;
    forkJoin([this.userService.getFuturaUser(true), this.userService.getReferral(), this.dynamicDialog.get('free_trial')])
      .pipe(finalize(() => (this.uiService.showSpinner = false)))
      .subscribe({
        next: ([user, link, dialog_content]) => {
          this.user = user;
          this.link = `${location.origin}/sh/${link}`;

          this.toptitle = dialog_content.toptitle;
          this.title = dialog_content.title;
          this.content = dialog_content.content;
          this.countdown_license = dialog_content.countdown_license;
          this.friends_to_invite = dialog_content.friends_to_invite;
          const messages = (dialog_content.share_texts as Array<string>) || [];
          this.message_choosed = messages[Math.floor(Math.random() * messages.length)].trim();

          if (this.message_choosed.includes('{link}')) {
            this.message_choosed = this.message_choosed.replace('{link}', this.link);
          }

          this.telegram_groups = dialog_content.telegram_groups;
          this.whatsapp_groups = dialog_content.whatsapp_groups;
          this.show_extra_groups = dialog_content.show_extra_groups;

          if (this.userInvitedLeft() == 0) {
            sessionStorage.removeItem('user_info');
            location.reload();
          }

          this.uiUpdate(user);
          this.ui_update_interval = setInterval(() => this.uiUpdate(user), 1000);
        },
        error: () => {
          this.uiService.errorToast({ title: this.translate.translate('utils.errors.general') });
          this.dialogRef.close();
        },
      });
  }

  uiUpdate(user: User) {
    const free_trial = user.content.licenses?.find(l => l.code == this.countdown_license);

    if (!free_trial) return;

    const free_trial_expire = new Date(+free_trial.expire);
    const now = new Date();

    const diff = free_trial_expire.getTime() - now.getTime();
    const start_at = new Date(+free_trial.activated_time);

    this.percent = Math.max(0, 100 - ((now.getTime() - start_at.getTime()) / (free_trial_expire.getTime() - start_at.getTime())) * 100);

    const hours = Math.max(0, Math.floor(diff / (1000 * 60 * 60)));
    const minutes = Math.max(0, Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)));
    const seconds = Math.max(0, Math.floor((diff % (1000 * 60)) / 1000));

    this.hour = hours.toString().padStart(2, '0');
    this.minute = minutes.toString().padStart(2, '0');
    this.second = seconds.toString().padStart(2, '0');
  }

  ngOnDestroy(): void {
    if (this.ui_update_interval) clearInterval(this.ui_update_interval);
  }

  canShare() {
    return navigator.share || navigator.clipboard;
  }

  removeHTMLTags(str: string): string {
    return str.replace(/<[^>]*>/g, '');
  }

  userInvitedCount(): number {
    if (!this.user) return 0;
    return (this.user.content.users_invited || []).length + (this.user.content.users_link_used || []).length;
  }

  userInvitedLeft(): number {
    if (!this.user) return 0;
    return this.friends_to_invite - this.userInvitedCount();
  }

  share() {
    if (navigator.share) {
      navigator.share({
        text: this.removeHTMLTags(this.message_choosed),
        url: this.link,
      });
    } else if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.link!)
        .then(() => {
          this.uiService.successToast({ title: this.translate.translate('dialogs.invite_friend.button_success') });
        })
        .catch(() => {
          this.uiService.errorToast({ title: this.translate.translate('utils.errors.device_doesnt_support_action') });
        });
    }
  }

  shareWhatsapp() {
    window.open(
      'https://wa.me/?text=' + encodeURIComponent(this.removeHTMLTags(this.message_choosed.split('<b>').join('*').split('</b>').join('*'))),
      '_blank'
    );
  }

  shareTelegram() {
    const groups = this.telegram_groups;
    const group = groups[Math.floor(Math.random() * groups.length)];

    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.message_choosed.split('<b>').join('*').split('</b>').join('*')).then(() => {
        alert('Codice invito copiato!');
        window.open(group, '_blank');
      });
    }
  }

  shareWhatsappG() {
    const groups = this.whatsapp_groups;
    const group = groups[Math.floor(Math.random() * groups.length)];

    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.message_choosed.split('<b>').join('*').split('</b>').join('*')).then(() => {
        alert('Codice invito copiato!');
        window.open(group, '_blank');
      });
    }
  }
}
