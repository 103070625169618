import { Component, computed, forwardRef, Inject, Optional, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InvalidLicenseForActionComponent } from '../../components/invalid-license-for-action/invalid-license-for-action.component';
import { TranslateModel } from '@futura/futura-ui/core';
import { TranslocoService } from '@jsverse/transloco';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

interface DialogData {
  element: { name: string; type?: 'page' | 'category' };
}

@Component({
  selector: 'app-invalid-license-for-action-dialog',
  templateUrl: './invalid-license-for-action-dialog.component.html',
  styleUrls: ['./invalid-license-for-action-dialog.component.scss'],
  standalone: true,
  imports: [forwardRef(() => InvalidLicenseForActionComponent)],
})
export class InvalidLicenseForActionDialogComponent {
  public readonly pageName = signal<string>('');
  public readonly translatedPage = signal<string>('');
  public readonly lockType = signal<string>('');

  public readonly iconTitle = computed<TranslateModel>(() => {
    const elementName = this.translatedPage();
    const lockType = this.lockType();

    if (lockType === 'page') {
      return {
        text: 'dialogs.invalid_license.title.page',
        translate: true,
        translateArgs: { page: elementName },
      } as TranslateModel;
    }

    if (lockType === 'category') {
      return {
        text: 'dialogs.invalid_license.title.category',
        translate: true,
      } as TranslateModel;
    }

    return {
      text: 'dialogs.invalid_license.title.default',
      translate: true,
    } as TranslateModel;
  });

  constructor(
    @Optional() private dialogRef: MatDialogRef<InvalidLicenseForActionDialogComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<InvalidLicenseForActionDialogComponent>,
    private translate: TranslocoService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) private bottomSheetData: DialogData
  ) {
    const data = dialogData || bottomSheetData;

    if (data?.element) {
      this.pageName.set(data.element.name);
      this.lockType.set(data.element.type || '');
      this.translatedPage.set(this.translate.translate(data.element.name + '.title'));
    }
  }

  public close(): void {
    this.dialogRef?.close();
    this.bottomSheetRef?.dismiss();
  }
}
