<!-- <div class="d-flex justify-content-between align-items-center" mat-dialog-title>
    <h2 class="m-0">{{'dialogs.invalid_license.sub_content.title' | transloco}}</h2>
    <mat-icon class="pointer" mat-dialog-close>close</mat-icon>
</div>

<div class="my-3" mat-dialog-content>
    <h4>{{'dialogs.invalid_license.sub_content.content' | transloco}}</h4>
    <input type="text" class="form-control form-control-lg custom-input w-100" [placeholder]="'profile.license.form.placeholder' | transloco" #license_input>
</div>

<div class="d-flex justify-content-end align-items-center" mat-dialog-actions>
    <button class="fut-btn fut-btn-outline mr-2" mat-dialog-close>{{'dialogs.info.close' | transloco}}</button>
    <button class='fut-btn fut-btn-secondary' (click)='verifyLicense(license_input.value); license_input.value = ""'>{{'profile.license.form.button' | transloco}}</button>
</div> -->

<div class="d-flex flex-column justify-content-center align-items-center">
  <img class="fut-emoji" src="../../../../assets/emoji/party.svg" alt="party emoji" />
  <h1 class="fut-h1 mt-2 mb-2">Attiva licenza</h1>

  <span class="text-center mb-1">Inserisci il codice licenza per sbloccare tutte le funzionalità.</span>
  <span class="text-center">Una volta inserito non potrà più essere utilizzato.</span>

  <mat-form-field appearance="outline" class="mt-4">
    <mat-label>{{ 'profile.license.form.placeholder' | transloco }}</mat-label>
    <input matInput [(ngModel)]="licenseValue" [placeholder]="'profile.license.form.placeholder' | transloco" type="text" />
  </mat-form-field>

  <div class="w-100 d-flex justify-content-end align-items-center">
    <button class="fut-btn fut-btn-link mr-2" mat-dialog-close>{{ 'dialogs.info.close' | transloco }}</button>
    <button futAnalyticsButton eventAction="update" eventTarget="insert" (click)="verifyLicense(); licenseValue = ''" class="fut-btn fut-btn-accent">
      Attiva codice
    </button>
  </div>
</div>
