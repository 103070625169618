import { Component, computed, inject, input, output, signal } from '@angular/core';
import { Router } from '@angular/router';
import { InsertLicenseCodeDialogComponent } from '../../dialogs/insert-license-code-dialog/insert-license-code-dialog.component';
import { FutDialogService } from '../../../core/dialog-service/fut-dialog.service';
import { TranslocoModule } from '@jsverse/transloco';
import { BaseDialogComponent } from '@futura/futura-ui/dialog';
import { IconTitleComponent } from '@futura/futura-ui/icon-title';
import { TranslateModel } from '@futura/futura-ui/core';
import { MatIcon } from '@angular/material/icon';
import { AliceAnimationsComponent } from '../../alice-animations/alice-animations.component';
import { environment } from 'src/environments/environment';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChipComponent } from '@futura/futura-ui/chip';
import { NgClass } from '@angular/common';
import { UserService } from 'src/app/core/user-service/user.service';
import { SuccessDialogComponent, SuccessDialogData } from '../../dialogs/success-dialog/success-dialog.component';

@Component({
  selector: 'app-invalid-license-for-action',
  templateUrl: './invalid-license-for-action.component.html',
  styleUrls: ['./invalid-license-for-action.component.scss'],
  standalone: true,
  imports: [TranslocoModule, BaseDialogComponent, IconTitleComponent, MatIcon, AliceAnimationsComponent, MatCheckboxModule, ChipComponent, NgClass],
})
export class InvalidLicenseForActionComponent {
  public readonly iconTitle = input<TranslateModel | string>('');
  public readonly lockName = input<string>(''); // this can only have values of name field from sidenavElements type SidenavElement
  public readonly lockType = input<string>('');
  public readonly goProductChange = output<void>();
  public readonly insertCodeChange = output<void>();
  public readonly vertical = signal<string>(environment.platform);
  public readonly testGroup = signal<string>('a');

  public readonly userService = inject(UserService);

  public readonly selectedProduct = signal<string>('');

  public readonly aliceMessage = computed(() => {
    const lockName = this.lockName();
    const lockType = this.lockType();

    if (!lockName) {
      return '';
    }

    if (lockType === 'category') {
      return 'dialogs.invalid_license.content.category';
    }

    return 'dialogs.invalid_license.alice.' + lockName;
  });

  constructor(
    private router: Router,
    private dialog: FutDialogService
  ) {
    this.userService.getFuturaUser().subscribe(user => {
      const instance = user.instance_id;
      const firstChar = instance[0];

      const charToNumber = firstChar.charCodeAt(0);
      const groupNumber = charToNumber % 3;

      const groups = ['a', 'b', 'c'];

      this.testGroup.set(groups[groupNumber] || 'c');

      console.log({ group: groups[groupNumber] });

      // console.log({ isAGroup });
    });
  }

  goProducts(): void {
    if (this.vertical() === 'ssm') {
      this.userService.tempSSMRequest(this.lockName(), this.testGroup(), this.selectedProduct()).subscribe();
      this.dialog.open<SuccessDialogComponent, SuccessDialogData>(SuccessDialogComponent, {
        matConf: {
          data: {
            title: "Grazie per l'interesse!",
            message: 'Ti ricontatteremo presto per aiutarti a scegliere la soluzione più adatta alle tue esigenze',
            submessage: '',
            auto_close: true,
          },
        },
        dimension: 'sm',
      });
    } else {
      this.router.navigate(['products']).then();
      this.goProductChange.emit();
    }
  }

  insertCode(): void {
    this.dialog.open(InsertLicenseCodeDialogComponent, { dimension: 'sm' });
    this.insertCodeChange.emit();
  }
}
