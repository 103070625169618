<fut-base-dialog *transloco="let translate">
  <fut-icon-title state="NONE" [iconTitle]="iconTitle()" image="/assets/emoji/unlock.png"></fut-icon-title>

  <div class="d-flex flex-column fut-gap-24 fut-m-t-24">
    @if (lockName()) {
      <div class="dark-tooltip fut-p-16">
        <div class="d-flex fut-gap-8 align-items-center">
          <app-alice-animations class="alice-width" type="static"></app-alice-animations>
          <div>{{ translate(aliceMessage()) }}</div>
        </div>
      </div>
    }

    @if (vertical() === 'ssm' && (testGroup() === 'a' || testGroup() === 'b')) {
      <div>
        <h4 class="fut-h4">Ottieni l'accesso completo a Futura, per sempre!</h4>
        <div
          class="d-flex fut-p-16 fut-text-light secondary-box mb-2"
          (click)="selectedProduct.set('€299')"
          [ngClass]="{ selected: selectedProduct() === '€299' }">
          <div class="d-flex justify-content-center"><mat-checkbox [checked]="selectedProduct() === '€299'"></mat-checkbox></div>
          <div class="w-100">
            <div class="d-flex justify-content-between align-items-center mb-2 w-100">
              <h3 class="fut-h3 m-0">Simulatore illimitato</h3>
              <h1 class="fut-h1 m-0" [hidden]="testGroup() === 'b'">€ 299</h1>
            </div>
            <div class="d-flex fut-gap-8 align-items-center fut-small">
              <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
              <span><b>Simulazioni d'esame illimitate</b> e tutte le modalità di esercitazione</span>
            </div>
            <div class="d-flex fut-gap-8 align-items-center fut-small">
              <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
              <span><b>Spiegazioni dettagliate</b> delle domande che hai sbagliato</span>
            </div>
          </div>
        </div>
        <div
          class="d-flex fut-p-16 fut-text-light secondary-box mb-1"
          (click)="selectedProduct.set('€599')"
          [ngClass]="{ selected: selectedProduct() === '€599' }">
          <div class="d-flex justify-content-center"><mat-checkbox [checked]="selectedProduct() === '€599'"></mat-checkbox></div>
          <div class="w-100">
            <div class="d-flex justify-content-between align-items-center mb-2 w-100">
              <div class="d-flex flex-column">
                <fut-chip [size]="'sm'" [type]="'secondary-200'">Più acquistato</fut-chip>
                <h3 class="fut-h3 m-0">Corso completo</h3>
              </div>
              <h1 class="fut-h1 m-0" [hidden]="testGroup() === 'b'">€ 599</h1>
            </div>
            <div class="d-flex fut-gap-8 align-items-center fut-small">
              <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
              <span>Tutto il pacchetto <b>Simulatore completo</b>: esercitazioni illimitate in tutte le modalità</span>
            </div>
            <div class="d-flex fut-gap-8 align-items-center fut-small">
              <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
              <span>Oltre <b>150 ore di videolezioni su tutti gli argomenti</b></span>
            </div>
            <div class="d-flex fut-gap-8 align-items-center fut-small">
              <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
              <span>Oltre <b>150 ore di lezioni live</b> per prepararti al meglio</span>
            </div>
            <div class="d-flex fut-gap-8 align-items-center fut-small">
              <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
              <span><b>Un tutor</b> a tua completa disposizione </span>
            </div>
          </div>
        </div>
      </div>
    } @else {
      <div class="d-flex flex-column fut-p-16 fut-gap-8 fut-text-light secondary-box">
        <h3 class="fut-h3">{{ translate('dialogs.invalid_license.list.title') }}</h3>
        <div class="d-flex fut-gap-8 align-items-center">
          <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
          <span [innerHTML]="translate('dialogs.invalid_license.list.1')"></span>
        </div>
        <div class="d-flex fut-gap-8 align-items-center">
          <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
          <span [innerHTML]="translate('dialogs.invalid_license.list.2')"></span>
        </div>
        <div class="d-flex fut-gap-8 align-items-center">
          <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
          <span [innerHTML]="translate('dialogs.invalid_license.list.3')"></span>
        </div>
      </div>
    }
  </div>

  <div *transloco="let translate" class="d-flex flex-column fut-gap-24 fut-m-t-24" actions>
    @if (vertical() === 'ssm') {
      <button class="fut-btn fut-btn-accent w-100" (click)="goProducts()">Contattaci</button>
    } @else {
      <button class="fut-btn fut-btn-accent w-100" (click)="goProducts()">{{ translate('dialogs.invalid_license.action.unlock_now') }}</button>
    }
    <div class="d-flex flex-row justify-content-between align-items-center">
      <span class="fut-text-light">{{ translate('dialogs.invalid_license.sub_content.title') }}</span>
      <button
        futAnalyticsButton
        eventAction="dialog"
        eventTarget="Attiva licenza"
        class="fut-btn fut-btn-link fut-text-secondary-900 insert-code-btn"
        (click)="insertCode()">
        {{ translate('profile.license.cta') }}
      </button>
    </div>
  </div>
</fut-base-dialog>
